
//http://localhost:8080/#/claim/EnquiryProgram
import { defineComponent, ref, reactive, toRefs, nextTick, onMounted, inject } from 'vue';
import { getQueryList, deleteInfo } from '@/API/claim/claimQuery';
import {
  getModelYear,
  getBuList,
  getRegionLst,
  getDealerList,
  getProgramList,
  getTypeClass,
  getRegion,
  getRegionDealerGroup,
  getAllParentBu
} from '@/API/salesReporting/claimCheckingReport';
import { message, TreeSelect } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
// import setColumnWidth from "@/utils/claim/setColumnWidth"
import downloadFile from '@/utils/claim/downloadFile';
import moment from 'moment';
import { useStore } from 'vuex';
import useFetch from '@/hooks/useFetch';
import * as echarts from 'echarts';
import { getDealerByGroupId, getIncentive } from '@/API/salesReporting';
import { useRoute } from 'vue-router';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import 'echarts/lib/component/tooltip';
// import axios from "axios";
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
const tableWidth = window.innerWidth;
const dealerCol = reactive([
  {
    title: '经销商编号 (Cofico)',
    dataIndex: 'dealerCodeCofico',
    width: 150,
    slots: { title: 'tableTitle' }
  },
  {
    title: '经销商编号 (SWT)',
    dataIndex: 'dealerCodeSwt',
    width: 140,
    slots: { title: 'tableTitle' }
  },
  {
    title: '经销商名称 (CN)',
    dataIndex: 'dealerNameCn',
    width: 130,
    slots: { title: 'tableTitle' }
  },
  {
    title: '经销商名称 (EN)',
    dataIndex: 'dealerNameEn',
    width: 130,
    slots: { title: 'tableTitle' }
  },
  {
    title: '经销商集团 (CN)',
    dataIndex: 'dealerGroupNameCn',
    width: 130,
    slots: { title: 'tableTitle' }
  },
  {
    title: '经销商集团 (EN)',
    dataIndex: 'dealerGroupNameEn',
    width: 130,
    slots: { title: 'tableTitle' }
  },
  {
    title: '经销商所在小区',
    dataIndex: 'subRegionCn',
    width: 130,
    slots: { title: 'tableTitle' }
  },
  {
    title: '业务类型',
    dataIndex: 'bu',
    width: 90,
    slots: { title: 'tableTitle' }
  },
  {
    title: '项目类型',
    dataIndex: 'programCategoryNameCn',
    width: 90,
    slots: { title: 'tableTitle' }
  },
  {
    title: '年',
    dataIndex: 'year',
    width: 50,
    slots: { title: 'tableTitle' }
  },
  {
    title: '季度',
    dataIndex: 'quarter',
    width: 120,
    slots: { title: 'tableTitle' }
  },
  {
    title: '月',
    dataIndex: 'month',
    width: 200,
    slots: { title: 'tableTitle' }
  },
  {
    title: '激励申请提交数量',
    dataIndex: 'submitedClaimNo',
    width: 200,
    slots: { title: 'tableTitle' }
  },
  {
    title: '审核通过申请数量',
    dataIndex: 'oemApprovedClaimNo',
    width: 200,
    slots: { title: 'tableTitle' }
  },
  {
    title: '审核通过申请金额',
    dataIndex: 'oemApprovedClaimAmount',
    width: 200,
    slots: { title: 'tableTitle' }
  },
  {
    title: '经销商确认通过申请数量',
    dataIndex: 'dealerConfirmedApprovalClaimNo',
    width: 200,
    slots: { title: 'tableTitle' }
  },
  {
    title: '经销商确认通过申请金额',
    dataIndex: 'dealerConfirmedApprovedClaimAmount',
    width: 200,
    slots: { title: 'tableTitle' }
  },
  {
    title: '经销商拒绝通过申请数量',
    dataIndex: 'dealerRejectedApprovedClaimNo',
    width: 200,
    slots: { title: 'tableTitle' }
  },
  {
    title: '经销商拒绝通过申请金额',
    dataIndex: 'dealerRejectedApprovedClaimAmount',
    width: 200,
    slots: { title: 'tableTitle' }
  },
  {
    title: '经销商未确认通过申请数量',
    dataIndex: 'dealerUnConfirmedApprovedClaimNo',
    width: 230,
    slots: { title: 'tableTitle' }
  },
  {
    title: '经销商未确认通过申请金额',
    dataIndex: 'dealerUnConfirmedApprovedClaimAmount',
    width: 230,
    slots: { title: 'tableTitle' }
  },
  {
    title: '审核未通过申请数量',
    dataIndex: 'oemRejectedClaimNo',
    width: 270,
    slots: { title: 'tableTitle' }
  },
  {
    title: '审核未通过申请金额',
    dataIndex: 'oemRejectedClaimAmount',
    width: 270,
    slots: { title: 'tableTitle' }
  },
  {
    title: '经销商确认未通过申请数量',
    dataIndex: 'dealerConfirmedRejectedClaimNo',
    width: 300,
    slots: { title: 'tableTitle' }
  },
  {
    title: '经销商确认未通过申请金额',
    dataIndex: 'dealerConfirmedRejectedClaimAmount',
    width: 300,
    slots: { title: 'tableTitle' }
  },
  {
    title: '经销商拒绝未通过申请数量',
    dataIndex: 'dealerAppealedClaimNo',
    width: 300,
    slots: { title: 'tableTitle' }
  },
  {
    title: '经销商拒绝未通过申请金额',
    dataIndex: 'dealerAppealedClaimAmount',
    width: 300,
    slots: { title: 'tableTitle' }
  },
  {
    title: '经销商未确认未通过申请数量',
    dataIndex: 'dealerUnConfirmedRejectedClaimNo',
    width: 330,
    slots: { title: 'tableTitle' }
  },
  {
    title: '经销商未确认未通过申请金额',
    dataIndex: 'dealerUnConfirmedRejectedClaimAmount',
    width: 330,
    slots: { title: 'tableTitle' }
  },
  {
    title: '审核中申请数量',
    dataIndex: 'oemPendingClaimNo',
    width: 200,
    slots: { title: 'tableTitle' }
  },
  // { title: "审核中申请金额", dataIndex: "oemPendingClaimAmount", width: 200 },
  {
    title: '未审核申请数量',
    dataIndex: 'oemUnCheckedClaimNo',
    width: 200,
    slots: { title: 'tableTitle' }
  },
  // { title: "未审核申请金额", dataIndex: "oemUnCheckedClaimAmount", width: 200 },
  {
    title: '首轮核查通过率',
    dataIndex: 'firstRoundPassRate',
    width: 270,
    slots: { title: 'tableTitle' }
  },
  {
    title: '确认最终核查通过率',
    dataIndex: 'finalPassRate',
    width: 200,
    slots: { title: 'tableTitle' }
  }
  // { title: "Operation", dataIndex: "checkResult", width: 200, slots: { customRender: "operation" } },
]);
const programCol = reactive([
  {
    title: '激励类型',
    dataIndex: 'incentiveCategoryCn',
    width: 90,
    slots: { title: 'tableTitle' }
  },
  {
    title: '项目类型',
    dataIndex: 'programCategoryNameCn',
    width: 200,
    slots: { title: 'tableTitle' }
  },
  {
    title: '项目编号',
    dataIndex: 'programCode',
    width: 200,
    slots: { title: 'tableTitle' }
  },
  {
    title: '项目名称',
    dataIndex: 'programNameCn',
    width: 200,
    slots: { title: 'tableTitle' }
  },
  {
    title: '项目期间',
    dataIndex: 'programSubmissionPeriodLst',
    width: 180,
    slots: { customRender: 'eligiblePeriod' }
  },
  {
    title: '激励申请数量',
    dataIndex: 'claimNo',
    width: 120,
    slots: { title: 'tableTitle' }
  },
  {
    title: '核查进度',
    children: [
      {
        title: '审核通过申请数量',
        dataIndex: 'approvedClaimNo',
        width: 200,
        slots: { title: 'tableTitle' }
      },
      {
        title: '审核未通过申请数量',
        dataIndex: 'rejectedClaimNo',
        width: 200,
        slots: { title: 'tableTitle' }
      },
      {
        title: '审核中申请数量',
        dataIndex: 'oemPendingClaimNo',
        width: 200,
        slots: { title: 'tableTitle' }
      },
      {
        title: '未审核申请数量',
        dataIndex: 'oemUnCheckedClaimNo',
        width: 200,
        slots: { title: 'tableTitle' }
      },
      {
        title: '确认最终核查通过率',
        dataIndex: 'finalPassRate',
        width: 200,
        slots: { title: 'tableTitle' }
      },
      {
        title: '确认最终核查未通过率',
        dataIndex: 'claimFailedRate',
        width: 200,
        slots: { title: 'tableTitle' }
      }
    ]
  }
  // { title: "Operation", dataIndex: "checkResult", width: 200, slots: { customRender: "operation" } },
]);
interface TreeDataItem {
  value: string;
  key: string;
  title?: string;
  disabled?: boolean;
  children?: TreeDataItem[];
}
const qmOptions: TreeDataItem[] = [
  {
    title: 'Q1',
    value: 'Q1',
    key: 'Q1',
    children: [
      {
        title: '01',
        value: '01',
        key: '01'
      },
      {
        title: '02',
        value: '02',
        key: '02'
      },
      {
        title: '03',
        value: '03',
        key: '03'
      }
    ]
  },
  {
    title: 'Q2',
    value: 'Q2',
    key: 'Q2',
    children: [
      {
        title: '04',
        value: '04',
        key: '04'
      },
      {
        title: '05',
        value: '05',
        key: '05'
      },
      {
        title: '06',
        value: '06',
        key: '06'
      }
    ]
  },
  {
    title: 'Q3',
    value: 'Q3',
    key: 'Q3',
    children: [
      {
        title: '07',
        value: '07',
        key: '07'
      },
      {
        title: '08',
        value: '08',
        key: '08'
      },
      {
        title: '09',
        value: '09',
        key: '09'
      }
    ]
  },
  {
    title: 'Q4',
    value: 'Q4',
    key: 'Q4',
    children: [
      {
        title: '10',
        value: '10',
        key: '10'
      },
      {
        title: '11',
        value: '11',
        key: '11'
      },
      {
        title: '12',
        value: '12',
        key: '12'
      }
    ]
  }
];
interface DealerType {
  dealerCode: string;
  dealerNameCn: string;
  programCode: string;
  eligiblePeriodStr: string;
  offerTypeName: string;
  optionName: string;
  vinNo: string;
  model: string;
  inParagraph: string;
  payRound: string;
  checkResult: string;
  complaintStatus: string;
  predictAmount: string;
  price: string;
  createdTime: string;
  status: string;
}
interface ProgramType {
  dealerCode: string;
  dealerNameCn: string;
  programCode: string;
  eligiblePeriodStr: string;
  offerTypeName: string;
  optionName: string;
  vinNo: string;
  model: string;
  inParagraph: string;
  payRound: string;
  checkResult: string;
  complaintStatus: string;
  predictAmount: string;
  price: string;
  createdTime: string;
  status: string;
}
interface TypeClassItem {
  typeClass: string;
}
type Key = ColumnProps['key'];
export default defineComponent({
  components: {},
  setup() {
    const { state } = useStore();
    const searchCon = ref();
    const tableHeight = ref();
    const tableHeight2 = ref();
    const getMonth = (quarter: number) => {
      let array: Array<string> = [];
      switch (quarter) {
        case 1:
          array = ['Q1'];
          break;
        case 2:
          array = ['Q2'];
          break;
        case 3:
          array = ['Q3'];
          break;
        case 4:
          array = ['Q4'];
          break;
      }
      return array;
    };
    const dateArray = getMonth(moment().quarter());
    //显示的表格数据
    const dealerListData = ref<any[]>([]);
    const programListData = ref<any[]>([]);
    const parameterList = ref<any>({
      bu: null,
      region: null,
      subRegion: null,
      make: null,
      typeClass: null,
      // modelYear: [],
      modelYear: null,
      claimPeriodYear: '',
      claimPeriodQM: null,
      programPeriodYear: '',
      programPeriodQM: null,
      checkingPeriodYear: '',
      checkingPeriodQM: null,
      delarCode: '',
      delarName: '',
      delarGroup: null
    });
    const isDownloadOpen = ref<boolean>(false);
    const route = useRoute();
    const store = useStore();
    const currentDealerCodes: string[] = [];
    const isRegion = store.state.user.organization.entityCode === 'Region';
    const judgeUser = (): Promise<any> => {
      const isDealerGroup = store.state.user.legalEntity.entityCode === 'Dealer Group';
      if (isDealerGroup || isRegion) {
        const groupId = store.state.user.orgid;
        return getDealerByGroupId(groupId).then((res: any) => {
          res.forEach((ele: any) => {
            currentDealerCodes.push(ele.dealerInfo.dealerCode);
          });
        });
      } else {
        return Promise.resolve().then(() => {
          currentDealerCodes.push(store.state.user.organization.entityCode);
        });
      }
    };
    //period初始化
    const claimPeriodYear = ref<string>(moment().format('YYYY'));
    const openClaimPeriodYear = ref<boolean>(false);
    const claimPeriodDis = ref<boolean>(false);
    const claimPeriodQM = ref<string[]>(dateArray);
    const handlerOpenClaimPeriodChange = (status: any): void => {
      openClaimPeriodYear.value = status;
    };
    const handlerPanelClaimPeriodChange = (value: string): void => {
      // console.log(598,value)
      const time = moment(value).format('YYYY');
      claimPeriodYear.value = time as string;
      openClaimPeriodYear.value = false;
      if (time) {
        claimPeriodDis.value = false;
      } else {
        claimPeriodDis.value = true;
        claimPeriodQM.value = [];
      }
    };
    const claimPeriodChange = (value: string) => {
      if (value) {
        claimPeriodDis.value = false;
      } else {
        claimPeriodDis.value = true;
        claimPeriodQM.value = [];
      }
    };
    const claimPeriodQMChange = (value: any) => {
      console.log(624, value);
    };
    const programPeriodYear = ref<string>(moment().format('YYYY'));
    const openProgramPeriodYear = ref<boolean>(false);
    const programPeriodDis = ref<boolean>(false);
    const programPeriodQM = ref<string[]>(dateArray);
    const handlerOpenProgramPeriodChange = (status: any): void => {
      openProgramPeriodYear.value = status;
    };
    const handlerPanelProgramPeriodChange = (value: string): void => {
      const time = moment(value).format('YYYY');
      programPeriodYear.value = time as string;
      openProgramPeriodYear.value = false;
      if (time) {
        programPeriodDis.value = false;
      } else {
        programPeriodDis.value = true;
        programPeriodQM.value = [];
      }
    };
    const programPeriodChange = (value: string) => {
      if (value) {
        programPeriodDis.value = false;
      } else {
        programPeriodDis.value = true;
        programPeriodQM.value = [];
      }
    };
    const checkingPeriodYear = ref<string>(moment().format('YYYY'));
    const openCheckingPeriodYear = ref<boolean>(false);
    const handlerOpenCheckingPeriodChange = (status: any): void => {
      if (status) {
        openCheckingPeriodYear.value = true;
      }
    };
    const handlerPanelCheckingPeriodChange = (value: string): void => {
      const time = moment(value).format('YYYY');
      checkingPeriodYear.value = time as string;
      openCheckingPeriodYear.value = false;
    };
    const getDefaultQm = (): string => {
      return 'Q' + moment().add('month', 1).quarter();
    };

    //获取modleYear选项
    const modelYearLst = ref<any>([]);
    const dogetModleYear = () => {
      getModelYear().then((res) => {
        const dataArr = ref<any>([]);
        res.forEach((i: any, ind: any) => {
          if (i !== null) {
            dataArr.value.push(i);
          }
        });
        modelYearLst.value = dataArr.value;
      });
    };
    dogetModleYear();
    //make选项
    const makeOptions = ref<any>([]);
    //获取bu选项,region选项
    const businessUnitSelect = ref<any>([]);
    const buList = state.user.buList;
    const buNameenList = ref<any>([]);
    const regionLst = ref<any>('');
    const regiondis = ref<any>(false);
    const subregionLst = ref<any>('');
    const subregiondis = ref<any>(true);
    const nowbuId = ref<any>('');
    const dogetRegion = (e: any) => {
      regionLst.value = [];
      if (parameterList.value.bu) {
        regiondis.value = false;
      } else {
        parameterList.value.region = null;
        regiondis.value = true;
        parameterList.value.subRegion = null;
        subregiondis.value = true;
      }

      businessUnitSelect.value.forEach((bui: any, buind: any) => {
        if (bui.nameEn === parameterList.value.bu) {
          nowbuId.value = bui.id;
        }
      });
      const regionParams = {
        buId: nowbuId.value,
        dealerCodes: currentDealerCodes
      };
      getRegionDealerGroup(regionParams).then((res) => {
        //console.log(866, res);
        // 北东西南 顺序排序
        const sortArr: any[] = [[], [], [], [], []];
        const maps = {
          n: 0,
          e: 1,
          s: 3,
          w: 2
        };
        res.forEach((region: any) => {
          const firstLetter = (region.regionEn[0] as string).toLocaleLowerCase();
          if (firstLetter in maps) {
            sortArr[maps[firstLetter]].push(region);
          } else {
            sortArr[4].push(region);
          }
        });
        //console.log(843, res);
        parameterList.value.region = undefined;
        regionLst.value = sortArr.flat();
      });
    };
    const dogetSubregion = (e: any) => {
      if (e) {
        subregiondis.value = false;
        /*getRegionLst({bu:parameterList.value.region}).then((res)=>{
                    parameterList.value.subRegion = null
                    subregionLst.value=res
                })*/
      } else {
        parameterList.value.subRegion = null;
        subregiondis.value = true;
      }
      if (store.state.user.legalEntity.entityCode === 'Dealer') {
        const regionParams = {
          buId: nowbuId.value,
          dealerCodes: currentDealerCodes
        };
        getRegionDealerGroup(regionParams).then((res) => {
          parameterList.value.subRegion = undefined;
          const subRegionArr = [];
          res.forEach((i: any, ind: any) => {
            const subObj = {
              id: i.subRegionId,
              regionCn: i.subRegionCn,
              regionEn: i.subRegionEn
            };
          });
          subregionLst.value = res;
        });
      } else {
        regionLst.value.forEach((rei: any, reind: any) => {
          if (rei.regionEn === parameterList.value.region) {
            console.log(956, rei);
            getRegion({
              buId: `${nowbuId.value}`,
              regionId: `${rei.regionId}`
            }).then((res) => {
              // 北东西南 顺序排序
              const sortArr: any[] = [[], [], [], [], []];
              const maps = {
                n: 0,
                e: 1,
                s: 3,
                w: 2
              };
              res.forEach((region: any) => {
                const firstLetter = (region.regionEn[0] as string).toLocaleLowerCase();
                if (firstLetter in maps) {
                  sortArr[maps[firstLetter]].push(region);
                } else {
                  sortArr[4].push(region);
                }
              });
              // subRegionList.value = sortArr.flat();

              parameterList.value.subRegion = undefined;
              subregionLst.value = sortArr.flat();
              console.log(982, subregionLst.value);
              // subregionLst.value=res
            });
          }
        });
      }
    };
    const dogetMake = (e: any) => {
      makeOptions.value = [];
      const makeLst = ref<any>([]);
      if (e.length > 0) {
        Array.from(e).forEach((i: any, ind: any) => {
          switch (i) {
            case 'MBPC':
              makeLst.value = makeLst.value.concat([{ make: 'CBU' }, { make: 'PbP' }]);
              break;
            case 'VAN':
              makeLst.value = makeLst.value.concat([{ make: 'VAN' }]);
              break;
            case 'DENZA':
              makeLst.value = makeLst.value.concat([{ make: 'DENZA' }]);
              break;
            default:
              makeLst.value = makeLst.value.concat([]);
              break;
          }
        });
        makeOptions.value = makeLst.value;
      } else {
        makeOptions.value = [];
      }
      //console.log(753, makeOptions.value);
    };
    const getBuList = () => {
      getAllParentBu().then((res) => {
        businessUnitSelect.value = res;
        businessUnitSelect.value = businessUnitSelect.value.filter((item: any) => {
          return item.nameEn !== 'Companycar';
        });
        // businessUnitSelect.value = state.user.buList;
        doGetBuList();
        //dogetParameter();
        console.log(111, businessUnitSelect.value);
        nextTick(() => {
          dogetAllData();
        });
      });
    };

    const doGetBuList = () => {
      console.log(businessUnitSelect.value);
      // getAllParentBu().then((res) => {
      //   businessUnitSelect.value = res;
      //   businessUnitSelect.value = businessUnitSelect.value.filter(
      //     (item: any) => {
      //       console.log(businessUnitSelect.value);
      //       return item.nameEn !== "Companycar";
      //     }
      //   );

      //   // businessUnitSelect.value = state.user.buList;

      // });
      const bulst = ref<any>([]);
      const hasmb = ref<any>(false);
      businessUnitSelect.value.forEach((i: any, ind: any) => {
        let isrepeat = false;
        bulst.value.forEach((bui: any, buind: any) => {
          if (i.nameEn === bui) {
            isrepeat = true;
          }
        });
        if (!isrepeat) {
          bulst.value.push(i.nameEn);
        }
        if (i.nameEn === 'MBPC') {
          hasmb.value = true;
        }
      });
      buNameenList.value = bulst.value;
      if (route.query.bu) {
        businessUnitSelect.value.forEach((bui: any, buind: any) => {
          if (route.query.bu === bui.id + '') {
            parameterList.value.bu = bui.nameEn;
            console.log(837, parameterList.value.bu);
          }
        });
      } else {
        if (hasmb.value) {
          parameterList.value.bu = 'MBPC';
        } else {
          parameterList.value.bu = businessUnitSelect.value[0].nameEn;
          console.log(businessUnitSelect.value[0].nameEn);
        }
      }
      dogetRegion([parameterList.value.bu]);
      dogetMake([parameterList.value.bu]);
    };

    //查询type class options
    const { data: typeClassOptions } = useFetch<TypeClassItem[]>({}, [], getTypeClass);

    //重置
    const handlerReset = () => {
      parameterList.value.bu = [];
      parameterList.value.region = null;
      parameterList.value.subRegion = null;
      parameterList.value.make = null;
      parameterList.value.typeClass = null;
      parameterList.value.modelYear = null;
      parameterList.value.claimPeriodYear = '';
      parameterList.value.claimPeriodQM = null;
      parameterList.value.programPeriodYear = '';
      parameterList.value.programPeriodQM = null;
      parameterList.value.checkingPeriodYear = '';
      parameterList.value.checkingPeriodQM = null;
      parameterList.value.delarCode = '';
      parameterList.value.delarName = '';
      parameterList.value.delarGroup = null;
      claimPeriodYear.value = moment().format('YYYY');
      programPeriodYear.value = moment().format('YYYY');
      checkingPeriodYear.value = moment().format('YYYY');
      // claimPeriodQM.value=[]
      // programPeriodQM.value = []
      claimPeriodQM.value = dateArray;
      programPeriodQM.value = dateArray;
      programPeriodDis.value = false;
      claimPeriodDis.value = false;
      doGetBuList();
    };

    const genQuarter = (year: string, param: Array<string> | string | undefined) => {
      const maps = {
        Q1: '1',
        Q2: '2',
        Q3: '3',
        Q4: '4'
      };
      let quaList: string[] = [];
      for (const qm of param as string[]) {
        if (qm in maps) {
          quaList = [...quaList, ...maps[qm]];
        }
      }
      const quarterList = quaList.map((mth: any) => {
        return `${year}-${mth}`;
      });
      return quarterList.length > 0 ? quarterList : undefined;
    };

    const genMonth = (year: string, param: Array<string>) => {
      const maps = {
        Q1: ['1-01', '1-02', '1-03'],
        Q2: ['2-04', '2-05', '2-06'],
        Q3: ['3-07', '3-08', '3-09'],
        Q4: ['4-10', '4-11', '4-12'],
        '01': ['1-01'],
        '02': ['1-02'],
        '03': ['1-03'],
        '04': ['2-04'],
        '05': ['2-05'],
        '06': ['2-06'],
        '07': ['3-07'],
        '08': ['3-08'],
        '09': ['3-09'],
        '10': ['4-10'],
        '11': ['4-11'],
        '12': ['4-12']
      };
      let mthList: string[] = [];
      for (const qm of param as string[]) {
        mthList = [...mthList, ...maps[qm]];
      }
      const monthList = mthList.map((mth: any) => {
        return `${year}-${mth}`;
      });
      return monthList.length > 0 ? monthList : undefined;
    };

    //整理获取parameter
    const parameterDataLst = ref<any>({
      bus: [],
      //secondBus: [],
      region: '',
      subRegion: '',
      make: '',
      typeClass: '',
      modelYear: '',
      dealerCode: '',
      dealerName: '',
      dealerGroup: '',
      claimPeriodYear: '',
      claimPeriodQuarter: [],
      claimPeriodMonth: [],
      programPeriodYear: '',
      programPeriodQuarter: [],
      programPeriodMonth: [],
      checkingPeriodId: [],
      currentDealerCodes: currentDealerCodes
    });
    const dogetParameter = () => {
      parameterDataLst.value.current = 0;
      parameterDataLst.value.size = -1;
      parameterDataLst.value.make = parameterList.value.make ? parameterList.value.make : '';
      parameterDataLst.value.typeClass = parameterList.value.typeClass ? parameterList.value.typeClass : '';
      parameterDataLst.value.modelYear = parameterList.value.modelYear;
      parameterDataLst.value.dealerCode = parameterList.value.delarCode;
      parameterDataLst.value.dealerName = parameterList.value.delarName;
      parameterDataLst.value.dealerGroup = parameterList.value.delarGroup ? parameterList.value.delarGroup : '';
      console.log(parameterList.value.bu);
      if (parameterList.value.bu) {
        // const currentBu = businessUnitSelect.value.find((i: any) => {
        //   console.log(222, i.nameEn);
        //   return i.nameEn === parameterList.value.bu;
        // });
        //const isSecondBu = currentBu.parentId !== null;
        let firstBuName = '';
        let secondBuName = '';
        // if (!isSecondBu) {
        firstBuName = parameterList.value.bu;
        // } else {
        secondBuName = parameterList.value.bu;
        //const parentId = currentBu.parentId;
        // firstBuName = businessUnitSelect.value.find((i: any) => {
        //   return i.id === parentId;
        // }).nameEn;
        // }
        parameterDataLst.value.bus = [firstBuName];
        //parameterDataLst.value.secondBus = secondBuName !== '' ? [secondBuName] : [];
        if (parameterList.value.region) {
          parameterDataLst.value.region = parameterList.value.region;
          if (parameterList.value.subRegion) {
            parameterDataLst.value.subRegion = parameterList.value.subRegion;
          }
        }
      } else {
        const currentBu = businessUnitSelect.value.find((i: any) => {
          return i.nameEn === buNameenList.value;
        });
        const isSecondBu = currentBu.parentId !== null;
        let firstBuName = '';
        let secondBuName = '';
        if (!isSecondBu) {
          firstBuName = buNameenList.value;
        } else {
          secondBuName = buNameenList.value;
          const parentId = currentBu.parentId;
          firstBuName = businessUnitSelect.value.find((i: any) => {
            return i.id === parentId;
          }).nameEn;
        }
        parameterDataLst.value.bus = [firstBuName];
        //parameterDataLst.value.secondBus = secondBuName !== '' ? [secondBuName] : [];
        // parameterDataLst.value.bus = [buNameenList.value];
      }

      if (claimPeriodQM.value.length > 0) {
        parameterDataLst.value.claimPeriodQuarter = genQuarter(claimPeriodYear.value, claimPeriodQM.value);
        parameterDataLst.value.claimPeriodMonth = genMonth(claimPeriodYear.value, claimPeriodQM.value);
      } else {
        parameterDataLst.value.claimPeriodQuarter = [];
        parameterDataLst.value.claimPeriodMonth = [];
      }
      parameterDataLst.value.claimPeriodYear = claimPeriodYear.value ? claimPeriodYear.value : '';

      if (programPeriodQM.value.length > 0) {
        parameterDataLst.value.programPeriodQuarter = genQuarter(programPeriodYear.value, programPeriodQM.value);
        parameterDataLst.value.programPeriodMonth = genMonth(programPeriodYear.value, programPeriodQM.value);
      } else {
        parameterDataLst.value.programPeriodQuarter = [];
        parameterDataLst.value.programPeriodMonth = [];
      }
      parameterDataLst.value.programPeriodYear = programPeriodYear.value ? programPeriodYear.value : '';
    };
    nextTick(() => {
      (document.getElementsByClassName('table-box')[0] as any).style.height =
        window.innerHeight - 180 - searchCon.value.$el.scrollHeight + 'px';
      const height = (document.getElementsByClassName('table-box')[0] as any).style.height;
      const heightNum = parseInt(height.substr(0, height.indexOf('px')));
      tableHeight.value = heightNum - 120;
      tableHeight2.value = heightNum - 160;
    });
    const doGetDealerData = () => {
      dogetParameter();
      getDealerList(parameterDataLst.value).then((res) => {
        dealerListData.value = res;
      });
    };
    const doGetProgramData = () => {
      dogetParameter();
      getProgramList(parameterDataLst.value).then((res) => {
        programListData.value = res;
        //console.log("programListData.value", programListData.value);
      });
    };
    const dogetAllData = () => {
      if (programPeriodYear.value) {
        doGetDealerData();
        doGetProgramData();
      } else {
        message.error('Program Period (Year) and BU must be selected!');
      }
    };

    //切换tab
    const tabN = ref<any>('Dealer Based');
    const tabChange = (e: any) => {
      if (e === '1') {
        tabN.value = 'Dealer Based';
      } else if (e === '2') {
        tabN.value = 'Program Based';
      }
    };
    const downloadTableData = [
      {
        file: '激励申请及核查项目明细报表',
        fileType: 'report'
      },
      {
        file: '激励申请及核查项目明细报表源数据',
        fileType: 'raw'
      }
    ];
    const downloadRowSelection = reactive<{
      selectedRowKeys: Key[];
      selectedRowData: any[];
      onChange: Function;
    }>({
      onChange: (selectedRowKeys: Key[], selectedRows: any[]) => {
        downloadRowSelection.selectedRowKeys = selectedRowKeys;
        downloadRowSelection.selectedRowData = selectedRows;
      },
      selectedRowData: [],
      selectedRowKeys: []
    });
    const downloadColumnsData = [{ title: '文件', dataIndex: 'file', align: 'left' }];
    const downloadVisible = ref(false);
    const openDownload = () => {
      downloadVisible.value = true;
    };
    const closeDownload = () => {
      downloadVisible.value = false;
      downloadRowSelection.selectedRowKeys.length = 0;
    };
    const handlerDownload = () => {
      if (downloadRowSelection.selectedRowKeys.length < 1) {
        message.warning('请先选择数据');
        return;
      } else {
        dogetParameter();
        let type = '';
        if (downloadRowSelection.selectedRowKeys.length === 2) {
          type = 'all';
        } else {
          type = downloadRowSelection.selectedRowKeys[0] as string;
        }
        if (type === 'all') {
          parameterDataLst.value.tabName = `${tabN.value}&Raw Data`;
        } else if (type === 'report') {
          parameterDataLst.value.tabName = tabN.value;
        } else if (type === 'raw') {
          parameterDataLst.value.tabName = `Raw Data`;
        }
        const paramsConfig = {
          // url: `/claimapi/dealer/communication/analysis/claim/checking/report/export?current=${parameterDataLst.value.current}${currentDealerCodesStr}&size=${parameterDataLst.value.size}&bus=${parameterDataLst.value.bus}${modelYearStr}${claimPeriodQuarterStr}${claimPeriodMonthStr}${programPeriodQuarterStr}${programPeriodMonthStr}&tabName=${parameterDataLst.value.tabName}&claimPeriodYear=${parameterDataLst.value.claimPeriodYear}&region=${parameterDataLst.value.region}&subRegion=${parameterDataLst.value.subRegion}&make=${parameterDataLst.value.make}&typeClass=${parameterDataLst.value.typeClass}&dealerCode=${parameterDataLst.value.dealerCode}&dealerName=${parameterDataLst.value.dealerName}&dealerGroup=${parameterDataLst.value.dealerGroup}`,
          url: `/claimapi/dealer/communication/analysis/claim/checking/report/export`,
          method: 'post',
          params: parameterDataLst.value
        };
        downloadFile(paramsConfig, 'application/vnd-excel; char-set=UTF-8');
        closeDownload();
      }
    };

    const myChart = ref<any>();
    const chartRef = ref<HTMLElement>();
    const queryChartData = () => {
      dogetParameter();
    };

    const chartVisible = ref<any>(false);
    const openChart = () => {
      chartVisible.value = true;
      dogetParameter();
      const chartParams = {
        current: 0,
        size: -1,
        bus: parameterDataLst.value.bus,
        // secondBus: parameterDataLst.value.secondBus,
        currentDealerCodes: currentDealerCodes,
        claimPeriodQuarter: parameterDataLst.value.claimPeriodQuarter,
        claimPeriodMonth: parameterDataLst.value.claimPeriodMonth,
        checkingPeriodId: parameterDataLst.value.checkingPeriodId,
        tabName: tabN.value,
        region: parameterDataLst.value.region,
        subRegion: parameterDataLst.value.subRegion,
        make: parameterDataLst.value.make,
        typeClass: parameterDataLst.value.typeClass,
        modelYear: parameterDataLst.value.modelYear,
        dealerCode: parameterDataLst.value.dealerCode,
        dealerName: parameterDataLst.value.dealerName,
        dealerGroup: parameterDataLst.value.dealerGroup,
        claimPeriodYear: parameterDataLst.value.claimPeriodYear,
        programPeriodQuarter: parameterDataLst.value.programPeriodQuarter,
        programPeriodMonth: parameterDataLst.value.programPeriodMonth,
        programPeriodYear: parameterDataLst.value.programPeriodYear
      };
      const unwarp = (obj: any) => obj && (obj.__v_raw || obj.valueOf() || obj);
      window.setTimeout(() => {
        if (chartRef.value) {
          myChart.value = echarts.init(chartRef.value as HTMLElement);
          getIncentive({ communicationReqVo: chartParams }).then((res) => {
            unwarp(myChart.value).setOption(res.option);
          });
        }
      }, 300);
    };
    const closeChart = () => {
      chartVisible.value = false;
    };

    onMounted(() => {
      getBuList();
      judgeUser();
      // Promise.all([judgeUser(), doGetBuList()]).then(() => {
      //   //doGetBuList();
      //   dogetAllData();
      // });
    });

    return {
      dateArray,
      getMonth,
      downloadRowSelection,
      downloadTableData,
      downloadColumnsData,
      closeDownload,
      openDownload,
      downloadVisible,
      currentDealerCodes,
      store,
      judgeUser,
      chartRef,
      myChart,
      queryChartData,
      closeChart,
      openChart,
      chartVisible,
      isDownloadOpen,
      nowbuId,
      tabN,
      tabChange,
      typeClassOptions,
      handlerDownload,
      dogetAllData,
      dogetMake,
      makeOptions,
      buNameenList,
      dogetParameter,
      claimPeriodQMChange,
      programPeriodChange,
      programPeriodDis,
      claimPeriodChange,
      claimPeriodDis,
      dogetSubregion,
      subregiondis,
      subregionLst,
      buList,
      handlerReset,
      dogetRegion,
      regionLst,
      regiondis,
      doGetBuList,
      businessUnitSelect,
      dogetModleYear,
      modelYearLst,
      handlerPanelCheckingPeriodChange,
      handlerOpenCheckingPeriodChange,
      openCheckingPeriodYear,
      checkingPeriodYear,
      handlerPanelProgramPeriodChange,
      handlerOpenProgramPeriodChange,
      openProgramPeriodYear,
      programPeriodYear,
      programPeriodQM,
      claimPeriodQM,
      qmOptions,
      handlerPanelClaimPeriodChange,
      handlerOpenClaimPeriodChange,
      openClaimPeriodYear,
      claimPeriodYear,
      parameterList,
      doGetProgramData,
      doGetDealerData,
      searchCon,
      tableWidth,
      tableHeight,
      tableHeight2,
      dealerCol,
      dealerListData,
      programListData,
      programCol,
      SHOW_PARENT
    };
  }
});
